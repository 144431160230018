.aajaBg {
    background-color: #fff;
    background-size: cover;
    -webkit-background-size: cover;
    width: 100%;
    height: 80px;
    overflow: hidden;
    position: relative;
    color: #fff
}

.aajaBg:after {
    content: "";
    width: 200%;
    height: 80px;
    padding-top: 70%;
    border-radius: 100%;
    background: #f9f5e9;
    position: absolute;
    top: 17%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0
}